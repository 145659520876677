/*
  font-face rules (custom fonts declarations)
*/
@font-face {
  src: url('/fonts/Baloo2-Regular.woff2') format('woff2');
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}


@font-face {
  src: url('/fonts/Baloo2-Medium.woff2') format('woff2');
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  src: url('/fonts/Baloo2-SemiBold.woff2') format('woff2');
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
  
@font-face {
  src: url('/fonts/Baloo2-Bold.woff2') format('woff2');
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
  
@font-face {
  src: url('/fonts/Baloo2-ExtraBold.woff2') format('woff2');
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
  
/*
  Tailwind classes
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  Basic styles
*/

@layer base {
  *,
  *::before,
  *::after {
    @apply border-0 box-border;
  }
  
  html {
    @apply text-px;
    scroll-behavior: smooth;
    text-size-adjust: 100%;
    text-rendering: optimizelegibility;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
  
  body {
    @apply font-body text-base leading-default;
  }

  h1,
  h2,
  h3,
  figure,
  p,
  ol {
    @apply m-0 p-0;
  }
  
  img,
  svg {
    @apply block;
  }
  
  img {
    @apply max-w-full h-auto;
  }
}

@keyframes shake {
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  11.78571% { transform:translate(5px,0) }
  13.57143% { transform:translate(0,0) }
  15.35714% { transform:translate(5px,0) }
  17.14286% { transform:translate(0,0) }
  18.92857% { transform:translate(5px,0) }
  20.71429% { transform:translate(0,0) }
  21.78571% { transform:translate(5px,0) }
  23.57143% { transform:translate(0,0) }
  25.35714% { transform:translate(5px,0) }
  27.14286% { transform:translate(0,0) }
  50.71429% { transform:translate(0,0) }
  51.78571% { transform:translate(5px,0) }
  53.57143% { transform:translate(0,0) }
  55.35714% { transform:translate(5px,0) }
  57.14286% { transform:translate(0,0) }
  58.92857% { transform:translate(5px,0) }
  60.71429% { transform:translate(0,0) }
  61.78571% { transform:translate(5px,0) }
  63.57143% { transform:translate(0,0) }
  65.35714% { transform:translate(5px,0) }
  67.14286% { transform:translate(0,0) }
  68.92857% { transform:translate(5px,0) }
  70.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}

.animate-shake {
  animation: shake 4420ms infinite ease both;
}

.animate-shake-once {
  animation: shake 4420ms ease;
}

@keyframes flying-witch {
  0% {
    transform: translateY(5%) rotate(7deg) translateX(5%);
  }
  25% {
    transform: translateY(0%) rotate(10deg) translateX(2.5%);
  }
  50% {
    transform: translateY(5%) rotate(4deg) translateX(0);
  }
  75% {
    transform: translateY(0%) rotate(10deg) translateX(-2.5%);
  }
  100% {
    transform: translateY(5%) rotate(7deg) translateX(5%);
  }
}


.animate-flying-witch {
  animation: flying-witch 2420ms infinite ease-in-out;
}

  .dialog-connect-wallet__button-connector {
    @apply focus:outline-none rounded-md bg-accent-green-200 bg-opacity-10 hover:bg-opacity-15 focus:bg-opacity-15 focus:border-solid focus:border-md focus:border-accent-green-100 focus:border-opacity-10 col-span-1 h-25 flex flex-col justify-center items-center;
  }

  .dialog-connect-wallet__button-connector__icon {
    @apply w-10;
  }

  .dialog-connect-wallet__button-connector__title {
    @apply text-base xs:text-md font-semibold text-neutral-fg-100 block mt-1;
  } 